/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


// Custom ng-select overrides
.ng-select {
  &.custom-select {
    .ng-select-container {
      border-radius: 8px;
      border-color: #ccc;
    }

    .ng-option {
      &.ng-option-selected {
        background-color: red;
      }
    }
  }
}

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import '@ionic/angular/css/palettes/dark.system.css';

// --------------------------------------------------------------------------------------------------------------------- SWIPER
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

// --------------------------------------------------------------------------------------------------------------------- BASE

@font-face {
  font-family: 'Norse';
  src: url('/assets/fonts/Norse.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Norse';
  src: url('/assets/fonts/Norse-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
// Font Event Composer
@font-face {
  font-family: 'Cardo';
  src: url('/assets/fonts/Cardo-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Cyberthic';
  src: url('/assets/fonts/Cyberthic.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Halloweek';
  src: url('/assets/fonts/Halloweek.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'MicroSquare';
  src: url('/assets/fonts/MicroSquare-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Nodesto';
  src: url('/assets/fonts/Nodesto-Caps-Condensed.ttf') format('truetype');
  font-style: normal;

}
// @font-face {
//   font-family: 'Nodesto';
//   src: url('/assets/fonts/Nodesto-Caps-Condensed-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

.text-capitalize {
  text-transform: capitalize;
}

.norse-font {
  font-family: 'Norse', sans-serif;
}

a.tertiary {
  color: var(--ion-color-tertiary);
}

ion-icon.huge {
  font-size: 64px;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.max-width {
  width: 100%;
  max-width: 1700px !important;
  margin-left: auto;
  margin-right: auto;
}

.fake-focus {
  position: fixed;
  top: -100px;
}

.vertical-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

ion-modal {
  --border-radius: 6px;
}

ion-modal ion-header.raised,
ion-modal ion-footer.raised {
  --ion-background-color: transparent;
  background: var(--ion-color-light);
}

ion-modal ion-header.raised ion-toolbar {
  border-bottom: 1px solid white;
  // box-shadow: rgba(0, 0, 0, 0.25) 10px 10px 16px 0;
}

ion-modal ion-footer.raised ion-toolbar {
  border-top: 1px solid white;
  // box-shadow: rgba(0, 0, 0, 0.25) 10px -10px 16px 0;
  padding-left: 6px;
  padding-right: 6px;
}

ion-modal ion-header.raised ion-searchbar {
  --box-shadow: none !important;
  --background: white !important;
  --border-radius: 6px !important;
}

ion-modal ion-content {
  --ion-background-color: transparent;
  background: var(--ion-color-light);
}









.form-error-text {
  font-size: 12px;
  color: var(--ion-color-danger)
}

// ion-modal.eventi-modal,
// ion-modal.login-modal {
//   --border-radius: 6px;
// }

@media (min-width: 768px) {
  ion-modal.high-modal {
    --height: 90vh;
  }

  ion-modal.login-modal {
    --max-width: 400px;
  }
}

.center-box {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  max-width: 800px;
  margin: 0 auto;
  padding: 8px 16px 32px 16px;
}

// .login-button {
//   outline: 2px solid var(--ion-color-tertiary);
//   outline-offset: 1px;
//   border-radius: 4px;
// }


// --------------------------------------------------------------------------------------------------------------------- SITE STRUCTURE
ion-content {
  overflow-y: auto;
  --overflow: auto;
}

ion-header.site-header ion-toolbar,
// ion-header.small-header ion-toolbar,
ion-footer.site-footer ion-toolbar,
ion-content.content-container {
  --background: transparent;
}

ion-header.small-header ion-toolbar {
  --background: var(--ion-color-light)
}

.content-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("./assets/odin-face.svg") no-repeat top -335px center;
  opacity: 0.05;
}

.content-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 88px;
  /* Adjust based on your header height */
  background: linear-gradient(90deg,
      white 0%,
      var(--ion-color-light) 5%,
      white 35%,
      var(--ion-color-light-shade) 49.8%,
      var(--ion-color-light-shade) 50%,
      var(--ion-color-light) 50.2%,
      white 65%,
      var(--ion-color-light-shade) 95%,
      var(--ion-color-light-shade) 100%);
}

.content-container.side {
  background: linear-gradient(90deg,
      white 0%,
      var(--ion-color-light) 5%,
      var(--ion-color-light) 65%,
      rgba(207, 204, 201, 1) 99.8%,
      rgba(207, 204, 201, 1) 100%);
}

.content-container.side::before {
  background: url("./assets/odin-face.svg") no-repeat top -335px right -490px;
}

@media (max-width: 992px) {
  .content-container::before {
    background: url("./assets/odin-face.svg") no-repeat top -335px right -490px;
  }

  // .content-container {
  //   background: linear-gradient(90deg,
  //       white 0%,
  //       var(--ion-color-light) 5%,
  //       var(--ion-color-light) 65%,
  //       var(--ion-color-light-shade) 99.8%,
  //       var(--ion-color-light-shade) 100%);
  // }
}

.main-content {
  flex: 1 0 auto;
  position: relative;
  padding-bottom: var(--ion-padding, 16px);
}

.main-content-flat-bg{
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--ion-color-light) 25%, var(--ion-color-light) 75%, rgba(255, 255, 255, 0) 100%);
  border-top: 8px solid rgba(0, 0, 0, .05);
  border-bottom: 8px solid rgba(0, 0, 0, .05);
}

ion-header.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease;
}

ion-header.small-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-110%);
  transition: transform 0.3s ease;
}

.ion-padding-double {
  padding: calc(16px * 2);
}

.ion-margin-half {
  padding: calc(16px / 2);
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

// --------------------------------------------------------------------------------------------------------------------- BACKGROUNDS
.back-squares{
  background-color: #f9f7f2;
    opacity: 1;
    background-image: linear-gradient(30deg, #eeece7 12%, transparent 12.5%, transparent 87%, #eeece7 87.5%, #eeece7), linear-gradient(150deg, #eeece7 12%, transparent 12.5%, transparent 87%, #eeece7 87.5%, #eeece7), linear-gradient(30deg, #eeece7 12%, transparent 12.5%, transparent 87%, #eeece7 87.5%, #eeece7), linear-gradient(150deg, #eeece7 12%, transparent 12.5%, transparent 87%, #eeece7 87.5%, #eeece7), linear-gradient(60deg, #eeece777 25%, transparent 25.5%, transparent 75%, #eeece777 75%, #eeece777), linear-gradient(60deg, #eeece777 25%, transparent 25.5%, transparent 75%, #eeece777 75%, #eeece777);
    background-size: 80px 140px;
    background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

.grid-col-bg {
  border-radius: 3px;
  background-color: var(--ion-color-light);
  border: 4px solid rgb(0 0 0 / 5%);
  margin-bottom: var(--ion-padding, 16px);
  position: relative;
  padding: 16px;
}

.grid-col-bg  ion-text h1,
.grid-col-bg  ion-text h2,
.grid-col-bg  ion-text h3 {
  margin: 0;
  margin-bottom: var(--ion-padding, 16px);
}

.grid-col-bg.ion-no-padding{
  padding: 0;
}

.grid-col-bg.round{
  border-radius: 64px;
}

.grid-col-bg.primary{
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
.grid-col-bg.secondary{
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}
.grid-col-bg.tertiary{
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}
.grid-col-bg.success{
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}
.grid-col-bg.warning{
  background-color: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}
.grid-col-bg.danger{
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}
.grid-col-bg.light{
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}
.grid-col-bg.medium{
  background-color: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
}
.grid-col-bg.dark{
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}

// .grid-col-bg::after {
//   content: "";
//   position: absolute;
//   height: 12px;
//   right: 0;
//   bottom: -17px;
//   left: 0;
//   background: url("./assets/odin-border.png") top right;
// }



.odin-box {
  width: 100%;
  border-radius: 3px;
  background-color: var(--ion-color-light);
  border: 4px solid var(--ion-color-light-shade);

  // background-image:
  //   linear-gradient(90deg, rgb(255, 255, 255, 0) 0%,
  //   var(--ion-color-light) 25%,
  //   var(--ion-color-light) 75%,
  //   rgb(255, 255, 255, 0) 100%);

  // border-top: 8px solid rgb(0 0 0 / 5%);
  // border-bottom: 8px solid rgb(0 0 0 / 5%);
}

.odin-box.h100{
  height: 100%;
}

.odin-box.all-center{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.odin-box.dice-bg{
  position: relative;
  overflow: hidden;
}
.odin-box.dice-bg::before{
  content: "";
    position: absolute;
    inset: 4%;
    opacity: 0.025;
    background: url('./assets/odin-dice.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: center;
    transform: rotate(16deg);
  }

.odin-box ion-text h1,
.odin-box ion-text h2,
.odin-box ion-text h3{
  display: block;
  margin: 0;
  padding: var(--ion-padding, 16px);
}

.odin-box ion-text h1 ion-icon,
.odin-box ion-text h2 ion-icon,
.odin-box ion-text h3 ion-icon{
  margin-right: 0.5rem;

}


.odin-box.ion-padding ion-text h1,
.odin-box.ion-padding ion-text h2,
.odin-box.ion-padding ion-text h3,
.odin-box.ion-padding-double ion-text h1,
.odin-box.ion-padding-double ion-text h2,
.odin-box.ion-padding-double ion-text h3 {
  margin: 0;
  padding: 0 0 var(--ion-padding, 16px) 0;
  margin-bottom: var(--ion-padding, 16px);
}

ion-label {
  font-size: 0.8rem;
}

.two-inputs {
  display: flex;
  gap: var(--ion-padding, 16px);
}

// --------------------------------------------------------------------------------------------------------------------- DIVIDER
.odin-divider {
  position: relative;
  height: 24px;
  overflow: visible;
  opacity: 0.3;
  text-align: left;
}

.odin-divider img {
  position: absolute;
  width: 20px;
  margin: 0 calc(50% - 10px);
  z-index: 3;
}

.odin-divider::before {
  content: '';
  position: absolute;
  top: 8px;
  right: 0;
  height: 1px;
  left: 0;
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 48%,
      rgba(0, 0, 0, 0) 49%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 51%,
      rgba(0, 0, 0, 1) 52%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%);
  z-index: 0;
}

.odin-divider::after {
  content: '';
  position: absolute;
  top: 10px;
    right: 0;
    height: 1px;
    left: 0;
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 45%,
      rgba(0, 0, 0, 1) 48%,
      rgba(0, 0, 0, 0) 49%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 51%,
      rgba(0, 0, 0, 1) 52%,
      rgba(0, 0, 0, 1) 55%,
      rgba(0, 0, 0, 0) 100%);
  z-index: 0;
}

.odin-divider.inverted {
  filter: invert(1);
  opacity: 0.5;
}


ion-loading.dice-loading {
  --background: none;
  --backdrop-opacity: .75;
  --spinner-svg: url('./assets/odin-dice.svg');
}

ion-loading.dice-loading .loading-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  font-family: norse;
  font-weight: bold;
  font-size: 18px;
  color: var(--ion-color-light);
  position: relative;
}

ion-loading.dice-loading .loading-wrapper .loading-content {
  margin: 0 !important;
}

// Hide the default ion-spinner
ion-loading.dice-loading ion-spinner {
  display: none;
}

// Add your custom spinner
ion-loading.dice-loading .loading-wrapper::before {
  content: '';
  width: 64px;
  height: 64px;
  margin-bottom: 15px;
  background: var(--spinner-svg) no-repeat center center;
  background-size: contain;
  animation: diceRoll 1.5s linear infinite;
  filter: brightness(0) invert(1); // Make the SVG white
  z-index: 1000;
}

@keyframes diceRoll {

  0% {
    transform: rotate(0deg) translateX(0) translateY(0);
  }

  25% {
    transform: rotate(90deg) translateX(0) translateY(-10px);
  }

  50% {
    transform: rotate(180deg) translateX(0) translateY(0);
  }

  75% {
    transform: rotate(270deg) translateX(0) translateY(-10px);
  }

  100% {
    transform: rotate(360deg) translateX(0) translateY(0);
  }
}

// --------------------------------------------------------------------------------------------------------------------- SEARCH AREA
// INPUTS MODS
ion-input,
ion-select {
  --background: white !important;
}

ion-input:not(.has-focus),
ion-select:not(.has-focus) {
  --border-color: var(--ion-color-light-shade) !important;
}

ion-textarea{
  --background: white !important;
}

.search-area {
  // background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--ion-color-light) 25%, var(--ion-color-light) 75%, rgba(255, 255, 255, 0) 100%);
  //   // border-top: 8px solid rgba(0, 0, 0, .05);
  //   // border-bottom: 8px solid rgba(0, 0, 0, .05);
  // position: relative;

  //   padding: 8px;



  h1 {
    padding: 0 8px 0 0 !important;
    white-space: nowrap;
  }

  ion-input,
  ion-select {
    margin-bottom: 0 !important;
    min-height: 46px !important;
  }

  ion-input:not(.has-focus, .has-value) .label-text-wrapper {
    -webkit-transform: translateY(75%) scale(1) !important;
    transform: translateY(75%) scale(1) !important;
  }

  ion-select:not(.select-expanded, .has-value)::part(label) {
    -webkit-transform: translateY(75%) scale(1);
    transform: translateY(75%) scale(1);
  }

  .buttons{
    display: flex;
    justify-content: flex-end;
  }
}

.no-result {
  background: var(--ion-color-light-shade);
  position: relative;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-result::before{
  content: '';
  position: absolute;
  inset: 0;
  background: var(--ion-color-light-shade);
  background: url("./assets/odin-dice.svg") no-repeat center center / cover;
  opacity: 0.02;
}

// --------------------------------------------------------------------------------------------------------------------- PAGER
.pager{
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  justify-content: center;
}

.pager > div{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-color-light-shade);
  border: 1px solid var(--ion-color-dark-tint);
  border-radius: 16px;
  width: 32px;
  height: 32px;
  color: var(--color);
  font-size: 14px;



}

.pager>div:hover{
  background-color: var(--ion-color-light);
}

.pager .current{
  cursor: auto;
  opacity: 0.4;
  border: 1px solid black;
}


ion-card{
  box-shadow: inset 0 0 32px rgb(255 255 255 / 20%);
    border: 4px solid rgb(0 0 0 / 5%);
    margin: 0;
}


.mess-user{
  color: var(--ion-color-primary);
}

.mess-event{
  color: var(--ion-color-tertiary);
}

.accedi-registrati-box{
  text-align: center;
  padding: 16px 32px 32px 32px;
}