// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
:root {
  --ion-mode: md !important;
}

:root {
  ion-button:not([shape="round"]):not([shape="circle"]) {
    --border-radius: 4px !important;
  }
}

ion-list:not(.sc-ion-select-popover-md) {
  background: transparent !important;
  border-radius: 3px !important;
  padding: 0 !important;
  border: 1px solid var(--ion-color-light-shade);

  ion-item {
    --background: transparent;
    background-image: linear-gradient(90deg, rgb(255 255 255) 0%, var(--ion-color-light) 100%);
  }
}

.odin-box ion-list:not(.sc-ion-select-popover-md) {
 border: none;
}

// ion-item {
//   --background: rgba(255, 255, 255, 0.5);
// }


:root {
  --border-radius: 32px;

  --ion-text-color: #555555;

  --ion-color-primary: #026270;
    --ion-color-primary-rgb: 2, 98, 112;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #025663;
    --ion-color-primary-tint: #1b727e;

    --ion-color-secondary: #038FA3;
    --ion-color-secondary-rgb: 3, 143, 163;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #037e8f;
    --ion-color-secondary-tint: #1c9aac;

    --ion-color-tertiary: #DD6E00;
    --ion-color-tertiary-rgb: 221, 110, 0;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #c26100;
    --ion-color-tertiary-tint: #e07d1a;

    --ion-color-success: #22aa46;
    --ion-color-success-rgb: 34, 170, 70;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #1e963e;
    --ion-color-success-tint: #38b359;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #c5000f;
    --ion-color-danger-rgb: 197, 0, 15;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #ad000d;
    --ion-color-danger-tint: #cb1a27;

    --ion-color-light: #FAF7F2;
    --ion-color-light-rgb: 250, 247, 242;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #ebe6e0;
    --ion-color-light-tint: #fbf8f3;

    --ion-color-medium: #a39c8f;
      --ion-color-medium-rgb: 163, 156, 143;
      --ion-color-medium-contrast: #ffffff;
      --ion-color-medium-contrast-rgb: 0, 0, 0;
      --ion-color-medium-shade: #8f897e;
      --ion-color-medium-tint: #aca69a;

    --ion-color-dark: #5c513d;
    --ion-color-dark-rgb: 92, 81, 61;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #514736;
    --ion-color-dark-tint: #6c6250;

}